<template>
    <div class="home_page">
        <div class="top_box">
            <el-carousel indicator-position="outside" height="530px" class="custom-indicator">
                <el-carousel-item v-for="(item, index) in imgList" :key="index">
                    <img class="lunbo_img" :src="item.img" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="center_box">
            <div class="winth_box">
                <div class="chanpin_top">
                    <span class="title_box"><strong>产品中心</strong></span>
                    <div style="text-align: center;">
                        <div class="wpline-wrapper horizontal-solid"></div>
                    </div>
                </div>
                <div class="chanpin_bottom">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="grid-content bg-purple" style="text-align: center;">
                                <img @click="goPage(1)" class="chanpin_img" src="../../assets/image/r9kd.png" alt="">
                                <p style="text-align: center;">企业数据治理工具 XGov </p>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content bg-purple">
                                <img @click="goPage(2)" class="chanpin_img" src="../../assets/image/sghs.png" alt="">
                                <p style="text-align: center;">数据集成处理中心 DIPC</p>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content bg-purple">
                                <img @click="goPage(3)" class="chanpin_img" src="../../assets/image/fqjb.png" alt="">
                                <p style="text-align: center;">数据同步集成平台 DataPump+</p>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content bg-purple">
                                <img @click="goPage(4)" class="chanpin_img" src="../../assets/image/g1qw.png" alt="">
                                <p style="text-align: center;">物联网中台 XIoT </p>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="chanpin_top">
                    <span class="title_box"><strong>解决方案</strong></span>
                    <div style="text-align: center;">
                        <div class="wpline-wrapper horizontal-solid"></div>
                    </div>
                </div>
                <div class="chanpin_bottom">
                    <el-row :gutter="20" style="margin: 20px 0">
                        <el-col :span="12">
                            <div class="grid-content bg-purple" style="text-align: center;">
                                <img @click="goFangan(1)" class="fangan_img" src="../../assets/image/qgeu.png" alt="">
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="grid-content bg-purple" style="text-align: center;">
                                <img @click="goFangan(2)" class="fangan_img" src="../../assets/image/bakk.png" alt="">
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" style="margin: 30px 0">
                        <el-col :span="12">
                            <div class="grid-content bg-purple" style="text-align: center;">
                                <img @click="goFangan(3)" class="fangan_img" src="../../assets/image/oyrr.png" alt="">
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="grid-content bg-purple" style="text-align: center;">
                                <img @click="goFangan(4)" class="fangan_img" src="../../assets/image/pzrl.png" alt="">
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" style="margin: 30px 0">
                        <el-col :span="12">
                            <div class="grid-content bg-purple" style="text-align: center;">
                                <img @click="goFangan(5)" class="fangan_img" src="../../assets/image/jtm9.png" alt="">
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="grid-content bg-purple" style="text-align: center;">
                                <img @click="goFangan(6)" class="fangan_img" src="../../assets/image/vlnn.png" alt="">
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" style="margin: 30px 0">
                        <el-col :span="12">
                            <div class="grid-content bg-purple" style="text-align: center;">
                                <img @click="goFangan(7)" class="fangan_img" src="../../assets/image/lfpm.png" alt="">
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="grid-content bg-purple" style="text-align: center;">
                                <img @click="goFangan(8)" class="fangan_img" src="../../assets/image/g1rs.png" alt="">
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgList: [
                { img: require('../../assets/image/ge16.jpg') },
                { img: require('../../assets/image/e0ud.jpg') },
            ]
        }
    },
    methods: {
        //  产品进入
        goPage(value) {
            if (value == 1) {
                this.$router.push({
                    path: "/xgov",
                });
            } else if (value == 2) {
                this.$router.push({
                    path: "/dipc",
                });
            } else if (value == 3) {
                this.$router.push({
                    path: "/datapump",
                });
            } else if (value == 4) {
                this.$router.push({
                    path: "/xlot",
                });
            }
        },
        //  方案进入
        goFangan(value){
            if (value == 1) {
                this.$router.push({
                    path: "/iofThings",
                });
            } else if (value == 2) {
                this.$router.push({
                    path: "/city",
                });
            } else if (value == 3) {
                this.$router.push({
                    path: "/logistics",
                });
            } else if (value == 4) {
                this.$router.push({
                    path: "/traffic",
                });
            } else if (value == 5) {
                this.$router.push({
                    path: "/travel",
                });
            } else if (value == 6) {
                this.$router.push({
                    path: "/warehouse",
                });
            } else if (value == 7) {
                this.$router.push({
                    path: "/povertyRelief",
                });
            } else if (value == 8) {
                this.$router.push({
                    path: "/manufacture",
                });
            }
        },
    },
}
</script>

<style lang="less" scoped>
.home_page {
    padding-top: 60px;

    .top_box {
        width: 100%;
        height: 530px;

        .lunbo_img {
            width: 100%;
            height: 530px;
        }
    }

    /deep/ul.el-carousel__indicators.el-carousel__indicators--horizontal.el-carousel__indicators--outside {
        position: absolute;
        bottom: 10px;
    }

    //     /deep/.custom-indicator .el-carousel__indicator {
    //     width: 10px;
    //     height: 10px;
    //     background-color: #409EFF;
    //     border-radius: 50%; /* 圆形指示器 */
    //   }

    //   /* 激活状态下的指示器样式 */
    //   /deep/.custom-indicator .el-carousel__indicator.is-active {
    //     background-color: #fff;
    //   }
    .center_box {
        margin-bottom: 100px;

        .winth_box {
            width: 1120px;
            margin: 0 auto;
            // background: red;
        }

        .chanpin_top {
            padding-top: 40px;
            text-align: center;

            .title_box {
                font-size: 28px;
                letter-spacing: 0px;
                font-family: SimHei;
                color: rgb(0, 0, 0);
            }

            .horizontal-solid {
                display: inline-block;
                width: 64px;
                border: none;
                border-top: 3px solid #ee4c12;
                height: 0;
                margin: 15px 0;
                overflow: hidden;
            }
        }

        .chanpin_img {
            cursor: pointer;
            width: 265px;
            height: 190px;
        }

        .chanpin_img:hover {
            transform: scale(1.1);
            transition: transform 0.3s ease;
        }

        .fangan_img {
            cursor: pointer;
            width: 540px;
            height: 127px;
        }

        .chanpin_bottom {}
    }
}
</style>