<template>
  <div id="app">
    <headTop/>
    <router-view />
    <footerView/>
  </div>
</template>
<script>
import headTop from './views/components/headTop.vue';
import footerView from './views/components/footerView.vue';
export default {
  components: {
    headTop,
    footerView,
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    onClick(){
      this.$router.push({
        path: "/resource",
      });
    }
  },
}
</script>
<style lang="less">
#app {
  // min-height: 800px;
}


</style>
