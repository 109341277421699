<template>
    <div class="head_top_page">
        <div class="top_list">
            <div class="logo_box">
                <img class="logo_img" src="../../assets//image/p26d.png" @click="goHome()" alt="">
            </div>
            <div class="top_list_box">
                <ul class="ul_box">
                    <li class="li_box">
                        <span class="sub" @click="goHome()">首页</span>
                    </li>
                    <li class="li_box chanpin">
                        <span class="sub">产品</span>
                        <ul class="son_ul">
                            <li class="son_li" @click="goPage(1)">企业数据治理工具 XGov</li>
                            <li class="son_li" @click="goPage(2)">数据集成处理中心 DIPC</li>
                            <li class="son_li" @click="goPage(3)">数据同步集成平台 DataPump+</li>
                            <li class="son_li" @click="goPage(4)">物联网中台 XIoT</li>
                            <li class="son_li" @click="goPage(5)">外部数据管理平台 xEDP</li>
                            <li class="son_li" @click="goPage(6)">隐私计算及联邦学习平台 xInsight</li>
                        </ul>
                    </li>
                    <li class="li_box chanpin">
                        <span class="sub">方案</span>
                        <ul class="son_ul">
                            <li class="son_li" style="width: 108px;left: 42px;" @click="gofangan(1)">物联网中台解决方案</li>
                            <li class="son_li" style="width: 108px;left: 42px;" @click="gofangan(2)">智慧旅游解决方案</li>
                            <li class="son_li" style="width: 108px;left: 42px;" @click="gofangan(3)">数据仓库解决方案</li>
                            <li class="son_li" style="width: 108px;left: 42px;" @click="gofangan(4)">智慧交通解决方案</li>
                            <li class="son_li" style="width: 108px;left: 42px;" @click="gofangan(5)">智能制造解决方案</li>
                            <li class="son_li" style="width: 108px;left: 42px;" @click="gofangan(6)">智慧物流解决方案</li>
                            <li class="son_li" style="width: 108px;left: 42px;" @click="gofangan(7)">智慧城市解决方案</li>
                            <li class="son_li" style="width: 108px;left: 42px;" @click="gofangan(8)">智慧扶贫解决方案</li>
                        </ul>
                    </li>
                    <li class="li_box">
                        <span class="sub" @click="goZyuan()">资源</span>
                    </li>
                    <li class="li_box chanpin">
                        <span class="sub">关于</span>
                        <ul class="son_ul">
                            <li class="son_li" style="width: 71px;left: 60px;" @click="goguanyu(1)">关于逸迅</li>
                            <li class="son_li" style="width: 71px;left: 60px;" @click="goguanyu(2)">大事记</li>
                            <li class="son_li" style="width: 71px;left: 60px;" @click="goguanyu(3)">新闻</li>
                            <li class="son_li" style="width: 71px;left: 60px;" @click="goguanyu(4)">技术团队</li>
                            <li class="son_li" style="width: 71px;left: 60px;" @click="goguanyu(5)">联系我们</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        goHome() {
            this.$router.push({
                path: "/",
            });
        },
        //  产品进入
        goPage(value) {
            if (value == 1) {
                this.$router.push({
                    path: "/xgov",
                });
            } else if (value == 2) {
                this.$router.push({
                    path: "/dipc",
                });
            } else if (value == 3) {
                this.$router.push({
                    path: "/datapump",
                });
            } else if (value == 4) {
                this.$router.push({
                    path: "/xlot",
                });
            } else if (value == 5) {
                this.$router.push({
                    path: "/xedp",
                });
            } else if (value == 6) {
                this.$router.push({
                    path: "/xlnsight",
                });
            }
        },
        //  方案进入
        gofangan(value) {
            if (value == 1) {
                this.$router.push({
                    path: "/iofThings"
                });
            } else if (value == 2) {
                this.$router.push({
                    path: "/travel",
                });
            } else if (value == 3) {
                this.$router.push({
                    path: "/warehouse",
                });
            } else if (value == 4) {
                this.$router.push({
                    path: "/traffic",
                });
            } else if (value == 5) {
                this.$router.push({
                    path: "/manufacture",
                });
            } else if (value == 6) {
                this.$router.push({
                    path: "/logistics",
                });
            }else if (value == 7) {
                this.$router.push({
                    path: "/city",
                });
            }else if (value == 8) {
                this.$router.push({
                    path: "/povertyRelief",
                });
            }
        },
        //  关于进入
        goguanyu(value) {
            if (value == 1) {
                this.$router.push({
                    path: "/yixun",
                });
            } else if (value == 2) {
                this.$router.push({
                    path: "/dashiji",
                });
            } else if (value == 3) {
                this.$router.push({
                    path: "/xinwen",
                });
            } else if (value == 4) {
                this.$router.push({
                    path: "/jishutuandui",
                });
            } else if (value == 5) {
                this.$router.push({
                    path: "/lianxiwomen",
                });
            } 
        },
        //  去资源页面
        goZyuan(){
            this.$router.push({
                    path: "/resource",
                });
        },
    },
}
</script>

<style lang="less" scoped>
.head_top_page {
    width: 100%;
    background: #ffffff;
    height: 60px;
    position: fixed;
    z-index: 99;

    .top_list {
        width: 1120px;
        position: fixed;
        height: 47px;
        z-index: 999;
        left: 391px;
        top: 0;
    }

    .logo_box {
        cursor: pointer;
        left: 44px;
        top: 5px;
        position: absolute;
        z-index: 100;
        width: 49px;
        height: 37px;
    }

    .logo_img {
        width: auto;
        height: 37px;
        top: 0px;
        left: -22px;
        position: relative;
    }

    .top_list_box {
        left: 287px;
        top: 0px;
        position: absolute;
        z-index: 102;
        width: 833px;
        height: 60px;
        display: block;

        .ul_box {
            width: auto;
            height: 47px;
            padding-left: 20px;
            background-color: #ffffff;
        }

        .li_box {
            cursor: pointer;
            z-index: 2;
            width: 137px;
            padding-left: 5px;
            padding-right: 5px;
            text-align: center;
            height: 47px;
            line-height: 51px;
            position: relative;
            float: left;
            width: 144px;
            padding-bottom: 0px;
            background-image: none;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: right top;
        }

        .sub {

            font-size: 13px;
            font-family: SimHei;
            font-weight: normal;
            color: #000000;
        }

        .li_box:hover {
            // line-height: 48px;
        }

        .li_box:hover .sub {
            color: #ee4c12;
            font-size: 12px;
        }

        .son_ul {
            z-index: 2;
            left: -57px;
            display: none;
            top: 47px;
            bottom: auto;
            position: absolute;
            background-color: transparent;
            margin-left: 0px;
        }

        .son_li {
            z-index: 3;
            width: 186px;
            line-height: 47px;
            height: 47px;
            text-align: center;
            background: -webkit-linear-gradient(left, rgb(247, 246, 246), rgb(255, 255, 255) 4%, rgb(255, 255, 255) 52%, rgb(255, 255, 255) 95%, rgb(247, 246, 246) 100%) !important;
            padding-left: 40px;
            padding-right: 36px;
            position: relative;
            float: left;
            padding-bottom: 0px;
            font-size: 12px;
            color: #5a5a5a;
        }

        .son_li:hover {
            color: #ee4c12;
            line-height: 40px;
        }

        .chanpin:hover .son_ul {
            display: block;
        }


    }
}
</style>