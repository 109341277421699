import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  // 首页
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import( "../views/AboutView.vue"),
  // },
  // 产品
  {
    path: "/product",
    name: "product",
    component: () =>
      import( "../views/product/index.vue"),
  },
  {
    path: "/xgov",
    name: "xgov",
    component: () =>
      import( "../views/product/xgov.vue"),
  },
  {
    path: "/dipc",
    name: "dipc",
    component: () =>
      import( "../views/product/dipc.vue"),
  },
  {
    path: "/datapump",
    name: "datapump",
    component: () =>
      import( "../views/product/datapump.vue"),
  },
  {
    path: "/xlot",
    name: "xlot",
    component: () =>
      import( "../views/product/xlot.vue"),
  },
  {
    path: "/xgcp",
    name: "xgcp",
    component: () =>
      import( "../views/product/xgcp.vue"),
  },
  {
    path: "/xedp",
    name: "xedp",
    component: () =>
      import( "../views/product/xedp.vue"),
  },
  {
    path: "/xlnsight",
    name: "xlnsight",
    component: () =>
      import( "../views/product/xlnsight.vue"),
  },
  {
    path: "/form1",
    name: "form1",
    component: () =>
      import( "../views/product/form1.vue"),
  },
  {
    path: "/form2",
    name: "form2",
    component: () =>
      import( "../views/product/form2.vue"),
  },
  // 方案
  {
    path: "/scheme",
    name: "scheme",
    component: () =>
      import( "../views/scheme/index.vue"),
  },
  {
    path: "/iofThings",
    name: "iofThings",
    component: () =>
      import( "../views/scheme/iofThings.vue"),
  },
  {
    path: "/travel",
    name: "travel",
    component: () =>
      import( "../views/scheme/travel.vue"),
  },
  {
    path: "/warehouse",
    name: "warehouse",
    component: () =>
      import( "../views/scheme/warehouse.vue"),
  },
  {
    path: "/traffic",
    name: "traffic",
    component: () =>
      import( "../views/scheme/traffic.vue"),
  },
  {
    path: "/manufacture",
    name: "manufacture",
    component: () =>
      import( "../views/scheme/manufacture.vue"),
  },
  {
    path: "/logistics",
    name: "logistics",
    component: () =>
      import( "../views/scheme/logistics.vue"),
  },
  {
    path: "/city",
    name: "city",
    component: () =>
      import( "../views/scheme/city.vue"),
  },
  {
    path: "/povertyRelief",
    name: "povertyRelief",
    component: () =>
      import( "../views/scheme/povertyRelief.vue"),
  },
  {
    path: "/anli",
    name: "anli",
    component: () =>
      import( "../views/scheme/anli.vue"),
  },

  // 资源
  {
    path: "/resource",
    name: "resource",
    component: () =>
      import( "../views/resource/index.vue"),
  },
  // 关于
  {
    path: "/about",
    name: "about",
    component: () =>
      import( "../views/about/index.vue"),
  },
  {
    path: "/yixun",
    name: "yixun",
    component: () =>
      import( "../views/about/yixun.vue"),
  },
  {
    path: "/dashiji",
    name: "dashiji",
    component: () =>
      import( "../views/about/dashiji.vue"),
  },
  {
    path: "/xinwen",
    name: "xinwen",
    component: () =>
      import( "../views/about/xinwen.vue"),
  },
  {
    path: "/newsDel",
    name: "newsDel",
    component: () =>
      import( "../views/about/newsDel.vue"),
  },
  {
    path: "/jishutuandui",
    name: "jishutuandui",
    component: () =>
      import( "../views/about/jishutuandui.vue"),
  },
  {
    path: "/lianxiwomen",
    name: "lianxiwomen",
    component: () =>
      import( "../views/about/lianxiwomen.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push
 
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}
export default router;
