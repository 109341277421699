<template>
    <div class="footer_page">
        <div class="footer_content">
            <img class="footer_img" src="../../assets//image/zymf.png" alt="">
            <p class="bottom_text">© 2013-2023 Yeexun Inc. | 上海逸迅信息科技有限公司版权所有. </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="less" scoped>
.footer_page {

    position: relative;
    bottom: 0;
    height: 47px;
    background: -webkit-linear-gradient(top, rgb(40, 41, 38) 31%, rgb(21, 25, 21) 81%);
    // left: -391.5px;
    width: 100%;
    .footer_content {
        width: 1120px;
        margin: 0 auto;
        position: relative;
        text-align: center;
    }
    .top_text {

    }
    .footer_img {
        width: 158px;
        margin-top: 5px;
    }
    .bottom_text {
        position: absolute;
        left: 57px;
        top: 28px;
        font-family: NSimSun;
        font-size: 14px;
        color:#ffffff;
    }
}
</style>